/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import $ from 'jquery'
export default function Home() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });

    const hero_slider = {
        loop: true,
        margin: 0,
        items: 1,
        dots: true,
        nav: true,
        navText: ["<i class='fa fa-angle-left'><i/>", "<i class='fa fa-angle-right'><i/>"],
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        smartSpeed: 1500,
        autoHeight: false,
        autoplay: true,
        mouseDrag: false,
        onInitialized: function (e) {
            var a = this.items().length;
            $("#snh-1").html(`<span>01</span><span>0${ a }</span>`);
            var presentage = Math.round((100 / a));
            $('.slider__progress span').css("width", presentage + "%");
        }
    }
    const testimonial__carousel = {
        loop: true,
        margin: 0,
        items: 1,
        dots: false,
        nav: true,
        navText: ["<span class='testimonial_carousel'><span/>", "<span class='testimonial_carousel'><span/>"],
        smartSpeed: 1200,
        autoHeight: false,
        autoplay: true,


    }
    const project_slider = {
        loop: true,
        margin: 0,
        items: 4,
        dots: false,
        nav: true,
        navText: ["<div class='arrow_carrot-left'><span/>", "<div class='arrow_carrot-right'><span/>"],
        smartSpeed: 1200,
        autoHeight: false,
        autoplay: true,
        responsive: {
            320: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    }
    const testimonial_client = {
        items: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        autoplay: true,
        asNavFor: '.testimonial__client',
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"><i></i></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"><i></i></i></button>',

    }
    const logo = {
        loop: true,
        margin: 80,
        items: 5,
        dots: false,
        nav: false,
        smartSpeed: 1200,
        autoHeight: false,
        autoplay: true,
        responsive: {
            320: {
                items: 2
            },
            576: {
                items: 3
            },
            992: {
                items: 5
            },
        }
    }
    const handleSliderChange = (e) => {
        var b = --e.item.index,
            a = e.item.count;
        $("#snh-1").html("<span> " + "0" + (1 > b ? b + a : b > a ? b - a : b) + "</span><span>" + "0" + a + "</span>");

        var current = e.page.index + 1;
        var presentage = Math.round((100 / e.page.count) * current);
        $('.slider__progress span').css("width", presentage + "%");
    }
    return (
        <>
            <body>


                {/* <!-- Offcanvas Menu Begin --> */}
                <div class="offcanvas-menu-overlay"></div>
                <div class="offcanvas-menu-wrapper">
                    <div class="offcanvas__logo">
                        <a href="#"><img src="img/logo.png" alt="" /></a>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                    <div class="offcanvas__widget">
                        <span>Call us for any questions</span>
                        <h4>+01 123 456 789</h4>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header class="header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="header__logo">
                                    <a href="/" class="header__logo-title"><img class="logo" src="img/logo/logo-1.png" width="40" alt="" /> The STEMM <span>Factor</span></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <nav class="header__menu mobile-menu">
                                    <ul>
                                        <li className='active'><a href="/">Home</a></li>
                                        <li><a href="/events">Events</a></li>
                                        <li><a href="/about">About</a></li>
                                        <li><a href="#">Resources</a>
                                            <ul class="dropdown">
                                                <li><a href="/bachelors">Bachelors</a></li>
                                                <li><a href="/masters">Masters</a></li>
                                                <li><a href="/phd">PhD</a></li>
                                                <li><a href="/services">Services</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/blog">Blog</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-3">
                                <div class="header__widget">
                                    <span>Call us for any questions</span>
                                    <h4>+01 123 456 789</h4>
                                </div>
                            </div>
                        </div>
                        <div class="canvas__open"><i class="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}

                {/* <!-- Hero Section Begin --> */}
                <section class="hero" >
                    <OwlCarousel class="hero__slider owl-carousel" {...hero_slider} onChange={(e) => handleSliderChange(e)}>
                        <div class="hero__items set-bg item" data-setbg="https://source.unsplash.com/random/1600x900">
                            <div class="hero__text">
                                <h2>Quality is not only our standard.</h2>
                                <a href="#" class="primary-btn">See Project</a>
                                <a href="#" class="more_btn">Discover more</a>
                                <div class="hero__social">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                    <a href="#"><i class="fa fa-instagram"></i></a>
                                    <a href="#"><i class="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="hero__items set-bg item" data-setbg="https://source.unsplash.com/random/1600x900">
                            <div class="hero__text">
                                <h2>Quality is not only our standard.</h2>
                                <a href="#" class="primary-btn">See Project</a>
                                <a href="#" class="more_btn">Discover more</a>
                                <div class="hero__social">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                    <a href="#"><i class="fa fa-instagram"></i></a>
                                    <a href="#"><i class="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                    <div class="slide-num" id="snh-1"></div>
                    <div class="slider__progress"><span></span></div>
                </section>
                {/* <!-- Hero Section End --> */}

                {/* <!-- About Section Begin --> */}
                <section class="about spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="about__text">
                                    <div class="section-title">
                                        <span>who are we</span>
                                        <h2>We propose and discuss design rules</h2>
                                    </div>
                                    <div class="about__para__text">
                                        <p>Metasurfaces are generally designed by placing scatterers in periodic or pseudo-periodic
                                            grids. We propose and discuss design rules for functional metasurfaces with randomly
                                            placed.</p>
                                        <p>Anisotropic elements that randomly sample. Quisque sit amet nisl ante. Fusce lacinia non
                                            tellus id gravida. Cras neque dolor, volutpat et hendrerit et.</p>
                                    </div>
                                    <a href="#" class="primary-btn normal-btn">Learn More</a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about__pic">
                                    <div class="about__pic__inner">
                                        <img src="img/about-pic.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- About Section End --> */}

                {/* <!-- Project Section Begin --> */}
                <section class="project">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="section-title">
                                    <span>Our works</span>
                                    <h2>Latest projects</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <OwlCarousel class="project__slider owl-carousel" {...project_slider}>
                                <div class="col-lg-3 ">
                                    <div class="project__slider__item set-bg" data-setbg="img/project/project-1.jpg">
                                        <div class="project__slider__item__hover">
                                            <span>interiors</span>
                                            <h5>Lower River Street Astoria</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 ">
                                    <div class="project__slider__item set-bg" data-setbg="img/project/project-2.jpg">
                                        <div class="project__slider__item__hover">
                                            <span>interiors</span>
                                            <h5>Lower River Street Astoria</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 ">
                                    <div class="project__slider__item set-bg" data-setbg="img/project/project-3.jpg">
                                        <div class="project__slider__item__hover">
                                            <span>interiors</span>
                                            <h5>Lower River Street Astoria</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 ">
                                    <div class="project__slider__item set-bg" data-setbg="img/project/project-4.jpg">
                                        <div class="project__slider__item__hover">
                                            <span>interiors</span>
                                            <h5>Lower River Street Astoria</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 ">
                                    <div class="project__slider__item set-bg" data-setbg="img/project/project-2.jpg">
                                        <div class="project__slider__item__hover">
                                            <span>interiors</span>
                                            <h5>Lower River Street Astoria</h5>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                {/* <!-- Project Section End --> */}

                {/* <!-- Services Section Begin --> */}
                <section class="services spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <span>Our specialization</span>
                                    <h2>What we do</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-1.png" alt="" />
                                    <h4>Interior Design</h4>
                                    <p>As you might expect of a company that began as a high-end interiors contractor, we pay strict
                                        attention.</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-2.png" alt="" />
                                    <h4>Office Design</h4>
                                    <p>Our commitment to exceptional quality has never wavered. To day ranks as one of the most
                                        highly-regarded construction.</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-3.png" alt="" />
                                    <h4>Home Design</h4>
                                    <p>Interdisciplinary architectural studio with cultural, residential and commercial projects
                                        built worldwide.</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-4.png" alt="" />
                                    <h4>Design drawing</h4>
                                    <p>Creating architectural and creative solutions to help people realize their vision and make
                                        them a reality.</p>
                                </div>
                            </div>
                        </div>
                        <div class="counter__content">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">85</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Projects<br /> Completed</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">127</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Happy<br /> Clients</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">36</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Awards<br /> Received</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">74</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Cups Of<br /> Coffee</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Services Section End --> */}

                {/* <!-- Testimonial Section Begin --> */}
                <section class="testimonial spad set-bg" data-setbg="img/testimonial/testimonial-bg.jpg">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="section-title">
                                    <span>Testimonials</span>
                                    <h2>What your clients say</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <OwlCarousel class="testimonial__carousel owl-carousel" {...testimonial__carousel}>
                                    <div class="testimonial__item">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-xl-9 col-lg-10">
                                                <p>“1. Fast and accurate at solving mental math problems involving addition,
                                                    subtraction, multiplication, division and percentages but without high-level
                                                    skills that might be required in jobs requiring complex calculation and
                                                    analysis.”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="testimonial__item">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-xl-9 col-lg-10">
                                                <p>“2. Fast and accurate at solving mental math problems involving addition,
                                                    subtraction, multiplication, division and percentages but without high-level
                                                    skills that might be required in jobs requiring complex calculation and
                                                    analysis.”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="testimonial__item">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-xl-9 col-lg-10">
                                                <p>“3. Fast and accurate at solving mental math problems involving addition,
                                                    subtraction, multiplication, division and percentages but without high-level
                                                    skills that might be required in jobs requiring complex calculation and
                                                    analysis.”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="testimonial__item">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-xl-9 col-lg-10">
                                                <p>“4. Fast and accurate at solving mental math problems involving addition,
                                                    subtraction, multiplication, division and percentages but without high-level
                                                    skills that might be required in jobs requiring complex calculation and
                                                    analysis.”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="testimonial__item">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-lg-9">
                                                <p>“5. Fast and accurate at solving mental math problems involving addition,
                                                    subtraction, multiplication, division and percentages but without high-level
                                                    skills that might be required in jobs requiring complex calculation and
                                                    analysis.”</p>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-6 col-lg-8 col-md-7 col-sm-10 ">
                                <OwlCarousel class="testimonial__client owl-carousel" {...testimonial_client}>
                                    <div class="testimonial__client__item add slick-active slick-center">
                                        <div class="testimonial__client__pic">
                                            <img src="img/testimonial/ta-1.png" alt="" />
                                        </div>
                                        <div class="testimonial__client__text">
                                            <h5>Renee Calhoun</h5>
                                            <span>CEO Woolley</span>
                                        </div>
                                    </div>
                                    <div class="testimonial__client__item add slick-active slick-center">
                                        <div class="testimonial__client__pic">
                                            <img src="img/testimonial/ta-2.png" alt="" />
                                        </div>
                                        <div class="testimonial__client__text">
                                            <h5>Renee Calhoun</h5>
                                            <span>CEO Woolley</span>
                                        </div>
                                    </div>
                                    <div class="testimonial__client__item add slick-active slick-center">
                                        <div class="testimonial__client__pic">
                                            <img src="img/testimonial/ta-3.png" alt="" />
                                        </div>
                                        <div class="testimonial__client__text">
                                            <h5>Renee Calhoun</h5>
                                            <span>CEO Woolley</span>
                                        </div>
                                    </div>
                                    <div class="testimonial__client__item add slick-active slick-center">
                                        <div class="testimonial__client__pic">
                                            <img src="img/testimonial/ta-4.png" alt="" />
                                        </div>
                                        <div class="testimonial__client__text">
                                            <h5>Renee Calhoun</h5>
                                            <span>CEO Woolley</span>
                                        </div>
                                    </div>
                                    <div class="testimonial__client__item add slick-active slick-center">
                                        <div class="testimonial__client__pic">
                                            <img src="img/testimonial/ta-5.png" alt="" />
                                        </div>
                                        <div class="testimonial__client__text">
                                            <h5>Renee Calhoun</h5>
                                            <span>CEO Woolley</span>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <OwlCarousel class="logo__carousel owl-carousel" {...logo}>
                                    <div class="logo__carousel__item">
                                        <a href="#"><img src="img/logo/logo-1.png" alt="" /></a>
                                    </div>
                                    <div class="logo__carousel__item">
                                        <a href="#"><img src="img/logo/logo-2.png" alt="" /></a>
                                    </div>
                                    <div class="logo__carousel__item">
                                        <a href="#"><img src="img/logo/logo-3.png" alt="" /></a>
                                    </div>
                                    <div class="logo__carousel__item">
                                        <a href="#"><img src="img/logo/logo-4.png" alt="" /></a>
                                    </div>
                                    <div class="logo__carousel__item">
                                        <a href="#"><img src="img/logo/logo-5.png" alt="" /></a>
                                    </div>
                                    <div class="logo__carousel__item">
                                        <a href="#"><img src="img/logo/logo-3.png" alt="" /></a>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Testimonial Section End --> */}

                {/* <!-- Team Section Begin --> */}
                <section class="team spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-6">
                                <div class="section-title">
                                    <span>Our Team</span>
                                    <h2>Meet our team</h2>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="team__btn">
                                    <a href="#" class="primary-btn normal-btn">View All</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="team__item set-bg" data-setbg="img/team/team-1.jpg">
                                    <div class="team__text">
                                        <div class="team__title">
                                            <h5>Dolores Webster</h5>
                                            <span>CEO & Founder</span>
                                        </div>
                                        <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                                            porta leo, non tincidunt mauris condimentum eget. Vivamus non turpis elit. Aenean
                                            ultricies nisl sit amet.</p>
                                        <div class="team__social">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                            <a href="#"><i class="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="team__item set-bg" data-setbg="img/team/team-2.jpg">
                                    <div class="team__text">
                                        <div class="team__title">
                                            <h5>Dana Vaughn</h5>
                                            <span>Architect</span>
                                        </div>
                                        <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                                            porta leo, non tincidunt mauris condimentum eget. Vivamus non turpis elit. Aenean
                                            ultricies nisl sit amet.</p>
                                        <div class="team__social">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                            <a href="#"><i class="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="team__item set-bg" data-setbg="img/team/team-3.jpg">
                                    <div class="team__text">
                                        <div class="team__title">
                                            <h5>Jonathan Mcdaniel</h5>
                                            <span>Architect</span>
                                        </div>
                                        <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                                            porta leo, non tincidunt mauris condimentum eget. Vivamus non turpis elit. Aenean
                                            ultricies nisl sit amet.</p>
                                        <div class="team__social">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                            <a href="#"><i class="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Team Section End --> */}

                {/* <!-- Call To Action Section Begin --> */}
                <section class="callto spad set-bg" data-setbg="img/call-bg.jpg">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-10 text-center">
                                <div class="callto__text">
                                    <span>Why choose us?</span>
                                    <h2>Our Ability To Deliver Outstanding Results For Our Clients Starts With Our Team Of Smart.
                                    </h2>
                                    <a href="#" class="primary-btn">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Call To Action Section End --> */}

                {/* <!-- Latest Section Begin --> */}
                <section class="latest spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-6">
                                <div class="section-title">
                                    <span>Latest News</span>
                                    <h2>From our blog</h2>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="latest__btn">
                                    <a href="#" class="primary-btn normal-btn">View All</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="latest__item">
                                    <div class="latest__item__pic">
                                        <img src="img/blog/latest-1.jpg" alt="" />
                                    </div>
                                    <div class="latest__item__text">
                                        <span>Architecture</span>
                                        <h4>Target and Amazon Shopping List for Home Stagers</h4>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="latest__item">
                                    <div class="latest__item__pic">
                                        <img src="img/blog/latest-2.jpg" alt="" />
                                    </div>
                                    <div class="latest__item__text">
                                        <span>Interior</span>
                                        <h4>6 Ideas for Team Building and Employee Appreciation for Home Stagers</h4>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="latest__item">
                                    <div class="latest__item__pic">
                                        <img src="img/blog/latest-3.jpg" alt="" />
                                    </div>
                                    <div class="latest__item__text">
                                        <span>Planning</span>
                                        <h4>How to Find the Best Price Structure for Your Home Staging Services</h4>
                                        <a href="#">Read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Latest Section End --> */}

                {/* <!-- Footer Section Begin --> */}
                <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
                    <div class="container">
                        <div class="footer__top">
                            <div class="row">
                                <div class="col-lg-8 col-md-6">
                                    <div class="footer__top__text">
                                        <h2>Ready To Work With Us?</h2>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="footer__top__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Enter your email..." />
                                            <button type="submit"><i class="fa fa-send"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" /></a>
                                    </div>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                    <div class="footer__social">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Company</h6>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Our Works</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="#">FAQs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#">Architecture</a></li>
                                        <li><a href="#">Interior Design</a></li>
                                        <li><a href="#">Exterior Design</a></li>
                                        <li><a href="#">Planning</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer__address">
                                    <h6>Get In Touch</h6>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="copyright">
                            <div class="row">
                                <div class="col-lg-8 col-md-7">
                                    <div class="copyright__text">
                                        <p>Copyright © <script>
                                            document.write(new Date().getFullYear());
                                        </script> All rights reserved | This template is made with <i class="fa fa-heart-o"
                                            aria-hidden="true"></i> by <a href="https://colorlib.com"
                                                target="_blank" rel="noreferrer">Colorlib</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="copyright__widget">
                                        <a href="#">Terms of use</a>
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- Footer Section End --> */}


            </body>

        </>
    )
}