/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import $ from 'jquery';
export default function Masters() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });
    return (
        <>
            <body>

                {/* <!-- Offcanvas Menu Begin --> */}
                <div class="offcanvas-menu-overlay"></div>
                <div class="offcanvas-menu-wrapper">
                    <div class="offcanvas__logo">
                        <a href="#"><img src="img/logo.png" alt="" /></a>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                    <div class="offcanvas__widget">
                        <span>Call us for any questions</span>
                        <h4>+01 123 456 789</h4>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header class="header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="header__logo">
                                    <a href="/" class="header__logo-title"><img class="logo" src="img/logo/logo-1.png" width="40" alt="" /> The STEMM <span>Factor</span></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <nav class="header__menu mobile-menu">
                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/events">Events</a></li>
                                        <li><a href="/about">About</a></li>
                                        <li className='active'><a href="#">Resoures</a>
                                            <ul class="dropdown">
                                                <li><a href="/bachelors">Bachelors</a></li>
                                                <li><a href="/masters">Masters</a></li>
                                                <li><a href="/phd">PhD</a></li>
                                                <li><a href="/services">Services</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/blog">Blog</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-3">
                                <div class="header__widget">
                                    <span>Call us for any questions</span>
                                    <h4>+01 123 456 789</h4>
                                </div>
                            </div>
                        </div>
                        <div class="canvas__open"><i class="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}

                {/* <!-- Breadcrumb Section Begin --> */}
                <div class="breadcrumb-option spad set-bg" data-setbg="https://source.unsplash.com/random/1600x900">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="breadcrumb__text">
                                    <h2>Masters</h2>
                                    <div class="breadcrumb__links">
                                        <a href="./index.html">Home</a>
                                        <span>Blogs</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Section End --> */}

                {/* <!-- Blog Section Begin --> */}
                <section class="blog spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="blog__item__list">
                                    <div class="blog__item">
                                        <ul>
                                            <li>December 20, 2019 </li>
                                            <li>By John Doe</li>
                                            <li>Planning</li>
                                        </ul>
                                        <h2>Target and Amazon Shopping List for Home Stagers</h2>
                                        <img src="img/blog/blog-1.jpg" alt="" />
                                        <div class="blog__item__text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan
                                                lacus vel facilisis. </p>
                                            <a href="#">Read more</a>
                                        </div>
                                    </div>
                                    <div class="blog__item__quote">
                                        <p>“Without question this is the stager you want to use! Jennifer staged a hard to sell home
                                            for me and we sold it fast! …. Jennifer made it possible.”</p>
                                        <span>Martin Lockhart</span>
                                    </div>
                                    <div class="blog__item">
                                        <ul>
                                            <li>December 20, 2019 </li>
                                            <li>By John Doe</li>
                                            <li>Planning</li>
                                        </ul>
                                        <h2>6 Ideas for Team Building & Employee Appreciation for Home Stagers</h2>
                                        <img src="img/blog/blog-2.jpg" alt="" />
                                        <div class="blog__item__text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan
                                                lacus vel facilisis. </p>
                                            <a href="#">Read more</a>
                                        </div>
                                    </div>
                                    <div class="blog__item">
                                        <ul>
                                            <li>December 20, 2019 </li>
                                            <li>By John Doe</li>
                                            <li>Planning</li>
                                        </ul>
                                        <h2>How to Find the Best Price Structure for Your Home Staging Services</h2>
                                        <img src="img/blog/blog-2.jpg" alt="" />
                                        <div class="blog__item__text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan
                                                lacus vel facilisis. </p>
                                            <a href="#">Read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="blog__pagination">
                                            <a href="#">1</a>
                                            <a href="#">2</a>
                                            <a href="#">3</a>
                                            <a href="#"><i class="fa fa-angle-double-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="blog__sidebar">
                                    <div class="blog__sidebar__about">
                                        <img class="author__pic" src="img/blog/blog-about.png" alt="" />
                                        <p>Quis ipsum suspendisse ultrices gravid. Risus commodo viverra maece accums lacus vel
                                            facilisis. </p>
                                        <img src="img/blog/signature.png" alt="" />
                                    </div>
                                    <div class="blog__sidebar__search">
                                        <form action="#">
                                            <input type="text" placeholder="Searach..." />
                                            <button type="submit"><span class="icon_search"></span></button>
                                        </form>
                                    </div>
                                    <div class="blog__sidebar__categories">
                                        <h5>Categories</h5>
                                        <ul>
                                            <li>Work Space</li>
                                            <li>Office Building</li>
                                            <li>HomeStay</li>
                                            <li>Making</li>
                                            <li>Process</li>
                                        </ul>
                                    </div>
                                    <div class="blog__sidebar__feature">
                                        <h5>Feature News</h5>
                                        <a href="#" class="blog__sidebar__feature__item">
                                            <div class="blog__sidebar__feature__item__pic">
                                                <img src="img/blog/br-1.jpg" alt="" />
                                            </div>
                                            <div class="blog__sidebar__feature__item__text">
                                                <span>December 20, 2019</span>
                                                <h6>Grace Millane's mother tells killer she died 'terrified</h6>
                                            </div>
                                        </a>
                                        <a href="#" class="blog__sidebar__feature__item">
                                            <div class="blog__sidebar__feature__item__pic">
                                                <img src="img/blog/br-2.jpg" alt="" />
                                            </div>
                                            <div class="blog__sidebar__feature__item__text">
                                                <span>December 20, 2019</span>
                                                <h6>A 'gregarious' gap-year student who dreamed.</h6>
                                            </div>
                                        </a>
                                        <a href="#" class="blog__sidebar__feature__item">
                                            <div class="blog__sidebar__feature__item__pic">
                                                <img src="img/blog/br-3.jpg" alt="" />
                                            </div>
                                            <div class="blog__sidebar__feature__item__text">
                                                <span>December 20, 2019</span>
                                                <h6>A 'gregarious' gap-year student who dreamed.</h6>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="blog__sidebar__tag">
                                        <h5>Tag</h5>
                                        <a href="#">Branding</a>
                                        <a href="#">Office</a>
                                        <a href="#">Creative</a>
                                        <a href="#">Building</a>
                                        <a href="#">Portfolio</a>
                                        <a href="#">Products</a>
                                        <a href="#">Website</a>
                                        <a href="#">Design</a>
                                        <a href="#">Working Space</a>
                                    </div>
                                    <div class="blog__sidebar__social">
                                        <h5>Follow Us</h5>
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                    <div class="blog__sidebar__newslatter">
                                        <h5>Newslatter</h5>
                                        <form action="#">
                                            <input type="text" placeholder="Your email address" />
                                            <button type="submit"><i class="fa fa-location-arrow"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Blog Section End --> */}

                {/* <!-- Footer Section Begin --> */}
                <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
                    <div class="container">
                        <div class="footer__top">
                            <div class="row">
                                <div class="col-lg-8 col-md-6">
                                    <div class="footer__top__text">
                                        <h2>Ready To Work With Us?</h2>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="footer__top__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Enter your email..." />
                                            <button type="submit"><i class="fa fa-send"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" /></a>
                                    </div>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                    <div class="footer__social">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Company</h6>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Our Works</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="#">FAQs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#">Architecture</a></li>
                                        <li><a href="#">Interior Design</a></li>
                                        <li><a href="#">Exterior Design</a></li>
                                        <li><a href="#">Planning</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer__address">
                                    <h6>Get In Touch</h6>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="copyright">
                            <div class="row">
                                <div class="col-lg-8 col-md-7">
                                    <div class="copyright__text">
                                        <p>Copyright © <script>
                                            document.write(new Date().getFullYear());
                                        </script> All rights reserved | This template is made with <i class="fa fa-heart-o"
                                            aria-hidden="true"></i> by <a href="https://colorlib.com"
                                                target="_blank">Colorlib</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="copyright__widget">
                                        <a href="#">Terms of use</a>
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- Footer Section End --> */}


            </body>


        </>
    )
}