/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import $ from 'jquery';
export default function Services() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });
    return (
        <>
            <body>

                {/* <!-- Offcanvas Menu Begin --> */}
                <div class="offcanvas-menu-overlay"></div>
                <div class="offcanvas-menu-wrapper">
                    <div class="offcanvas__logo">
                        <a href="#"><img src="img/logo.png" alt="" /></a>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                    <div class="offcanvas__widget">
                        <span>Call us for any questions</span>
                        <h4>+01 123 456 789</h4>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header class="header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="header__logo">
                                    <a href="/" class="header__logo-title"><img class="logo" src="img/logo/logo-1.png" width="40" alt="" /> The STEMM <span>Factor</span></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <nav class="header__menu mobile-menu">
                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/events">Events</a></li>
                                        <li><a href="/about">About</a></li>
                                        <li className='active'><a href="#">Resoures</a>
                                            <ul class="dropdown">
                                                <li><a href="/bachelors">Bachelors</a></li>
                                                <li><a href="/masters">Masters</a></li>
                                                <li><a href="/phd">PhD</a></li>
                                                <li><a href="/services">Services</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/blog">Blog</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-3">
                                <div class="header__widget">
                                    <span>Call us for any questions</span>
                                    <h4>+01 123 456 789</h4>
                                </div>
                            </div>
                        </div>
                        <div class="canvas__open"><i class="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}

                {/* <!-- Breadcrumb Section Begin --> */}
                <div class="breadcrumb-option spad set-bg" data-setbg="https://source.unsplash.com/random/1600x900">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="breadcrumb__text">
                                    <h2>Our Services</h2>
                                    <div class="breadcrumb__links">
                                        <a href="./index.html">Home</a>
                                        <span>Our Services</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Section End --> */}

                {/* <!-- Services Section Begin --> */}
                <section class="services services-page spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <span>Our specialization</span>
                                    <h2>What we do</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-1.png" alt="" />
                                    <h4>Interior Design</h4>
                                    <p>As you might expect of a company that began as a high-end interiors contractor, we pay strict
                                        attention.</p>
                                    <ul>
                                        <li>- Energy allocation.</li>
                                        <li>- Fire protection, safety</li>
                                        <li>- Lifts and escalators.</li>
                                        <li>- Facade engineering.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-2.png" alt="" />
                                    <h4>Office Design</h4>
                                    <p>Our commitment to exceptional quality has never wavered. To day ranks as one of the most
                                        highly-regarded construction.</p>
                                    <ul>
                                        <li>- Active system.</li>
                                        <li>- Air conditioning.</li>
                                        <li> -BSRIA blue book.</li>
                                        <li>- Building services engineer.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-3.png" alt="" />
                                    <h4>Home Design</h4>
                                    <p>Interdisciplinary architectural studio with cultural, residential and commercial projects
                                        built worldwide.</p>
                                    <ul>
                                        <li>- Pipework.</li>
                                        <li>- Plant room.</li>
                                        <li> - Plumbing.</li>
                                        <li>- Rules of Thumb</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="services__item">
                                    <img src="img/services/services-4.png" alt="" />
                                    <h4>Design drawing</h4>
                                    <p>Creating architectural and creative solutions to help people realize their vision and make
                                        them a reality.</p>
                                    <ul>
                                        <li>- Guidelines for building.</li>
                                        <li>- Thermal comfort.</li>
                                        <li>- Types of building services.</li>
                                        <li>- Types of heating system.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Services Section End --> */}

                {/* <!-- Counter Section Begin --> */}
                <section class="counter spad set-bg" data-setbg="img/counter-bg.jpg">
                    <div class="container">
                        <div class="counter__content">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">85</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Projects<br /> Completed</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">127</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Happy<br /> Clients</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">36</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Awards<br /> Received</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="counter__item">
                                        <div class="counter__item__number">
                                            <h2 class="count">74</h2>
                                        </div>
                                        <div class="counter__item__text">
                                            <h5>Cups Of<br /> Coffee</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Counter Section End --> */}


                <section class="services services-page spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <span>Careers</span>
                                    <h2>Career with </h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus libero erat,
                                tempor a lacus vitae, eleifend lobortis metus. Sed lobortis rhoncus ante ac gravida. Maecenas pharetra molestie elit.</p>
                        </div>
                    </div>
                </section>

                {/* <!-- Client Section Begin --> */}
                <section class="clients spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <div class="section-title">
                                    <span>Branding</span>
                                    <h2>Our Clients</h2>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <a href="#"><img src="img/logo/logo-1.png" alt="" /></a>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <a href="#"><img src="img/logo/logo-2.png" alt="" /></a>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <a href="#"><img src="img/logo/logo-3.png" alt="" /></a>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <a href="#"><img src="img/logo/logo-4.png" alt="" /></a>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <a href="#"><img src="img/logo/logo-3.png" alt="" /></a>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <a href="#"><img src="img/logo/logo-5.png" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Client Section End --> */}

                {/* <!-- Footer Section Begin --> */}
                <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
                    <div class="container">
                        <div class="footer__top">
                            <div class="row">
                                <div class="col-lg-8 col-md-6">
                                    <div class="footer__top__text">
                                        <h2>Ready To Work With Us?</h2>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="footer__top__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Enter your email..." />
                                            <button type="submit"><i class="fa fa-send"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-dm-6">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" /></a>
                                    </div>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                    <div class="footer__social">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Company</h6>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Our Works</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="#">FAQs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#">Architecture</a></li>
                                        <li><a href="#">Interior Design</a></li>
                                        <li><a href="#">Exterior Design</a></li>
                                        <li><a href="#">Planning</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer__address">
                                    <h6>Get In Touch</h6>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="copyright">
                            <div class="row">
                                <div class="col-lg-8 col-md-7">
                                    <div class="copyright__text">
                                        <p>Copyright © <script>
                                            document.write(new Date().getFullYear());
                                        </script> All rights reserved | This template is made with <i class="fa fa-heart-o"
                                            aria-hidden="true"></i> by <a href="https://colorlib.com"
                                                target="_blank">Colorlib</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="copyright__widget">
                                        <a href="#">Terms of use</a>
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- Footer Section End --> */}


            </body>
        </>
    )
}