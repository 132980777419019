/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import $ from 'jquery';
export default function About() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });
    return (
        <>
            <body>
                {/* <!-- Offcanvas Menu Begin --> */}
                <div class="offcanvas-menu-overlay"></div>
                <div class="offcanvas-menu-wrapper">
                    <div class="offcanvas__logo">
                        <a href="#"><img src="img/logo.png" alt="" /></a>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                    <div class="offcanvas__widget">
                        <span>Call us for any questions</span>
                        <h4>+01 123 456 789</h4>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header class="header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="header__logo">
                                    <a href="/" class="header__logo-title"><img class="logo" src="img/logo/logo-1.png" width="40" alt="" /> The STEMM <span>Factor</span></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <nav class="header__menu mobile-menu">
                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/events">Events</a></li>
                                        <li class="active"><a href="/about">About</a></li>
                                        <li><a href="#">Resources</a>
                                            <ul class="dropdown">
                                                <li><a href="/bachelors">Bachelors</a></li>
                                                <li><a href="/masters">Masters</a></li>
                                                <li><a href="/phd">PhD</a></li>
                                                <li><a href="/services">Services</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/blog">Blog</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-3">
                                <div class="header__widget">
                                    <span>Call us for any questions</span>
                                    <h4>+01 123 456 789</h4>
                                </div>
                            </div>
                        </div>
                        <div class="canvas__open"><i class="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}

                {/* <!-- Breadcrumb Section Begin --> */}
                <div class="breadcrumb-option spad set-bg" data-setbg="https://source.unsplash.com/random/1600x900">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="breadcrumb__text">
                                    <h2>About Us</h2>
                                    <div class="breadcrumb__links">
                                        <a href="./index.html">Home</a>
                                        <span>About Us</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Section End --> */}

                {/* <!-- About Page Section Begin --> */}
                <section class="about-page spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="about__text about__page__text">
                                    <div class="section-title">
                                        <span>who are we</span>
                                        <h2>About Us</h2>
                                    </div>
                                    <div class="about__para__text">
                                        <p>As you might expect of a company that began as a high-end interiors contractor.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8">
                                <div class="about__page__services">
                                    <div class="about__page__services__text">
                                        <p>Metasurfaces are generally designed by placing scatterers in periodic or pseudo-periodic
                                            grids. We propose and discuss design rules for functional metasurfaces with randomly
                                            placed. Anisotropic elements that randomly sample. Quisque sit amet nisl ante.</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="services__item">
                                                <img src="img/services/services-5.png" alt="" />
                                                <h4>Our Mission</h4>
                                                <p>As you might expect of a company that began as a high-end interiors contractor,
                                                    we pay strict attention. Anisotropic elements that randomly sample.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="services__item">
                                                <img src="img/services/services-6.png" alt="" />
                                                <h4>Our Vision</h4>
                                                <p>Our commitment to exceptional quality has never wavered. To day ranks as one of
                                                    the most highly-regarded construction. Anisotropic elements that randomly
                                                    sample.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- About Page Section End --> */}

                {/* <!-- Call To Action Section Begin --> */}
                <section class="callto spad set-bg" data-setbg="img/call-bg.jpg">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-10 text-center">
                                <div class="callto__text">
                                    <span>Why choose us?</span>
                                    <h2>Our ability to bring outstanding results to our customers.</h2>
                                    <a href="#" class="primary-btn">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Call To Action Section End --> */}

                {/* <!-- Team Section Begin --> */}
                <section class="team spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-6">
                                <div class="section-title">
                                    <span>Our Team</span>
                                    <h2>Meet our team</h2>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <div class="team__btn">
                                    <a href="#" class="primary-btn normal-btn">View All</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="team__item set-bg" data-setbg="img/team/team-1.jpg">
                                    <div class="team__text">
                                        <div class="team__title">
                                            <h5>Dolores Webster</h5>
                                            <span>CEO & Founder</span>
                                        </div>
                                        <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                                            porta leo, non tincidunt mauris condimentum eget. Vivamus non turpis elit. Aenean
                                            ultricies nisl sit amet.</p>
                                        <div class="team__social">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                            <a href="#"><i class="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="team__item set-bg" data-setbg="img/team/team-2.jpg">
                                    <div class="team__text">
                                        <div class="team__title">
                                            <h5>Dana Vaughn</h5>
                                            <span>Architect</span>
                                        </div>
                                        <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                                            porta leo, non tincidunt mauris condimentum eget. Vivamus non turpis elit. Aenean
                                            ultricies nisl sit amet.</p>
                                        <div class="team__social">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                            <a href="#"><i class="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="team__item set-bg" data-setbg="img/team/team-3.jpg">
                                    <div class="team__text">
                                        <div class="team__title">
                                            <h5>Jonathan Mcdaniel</h5>
                                            <span>Architect</span>
                                        </div>
                                        <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                                            porta leo, non tincidunt mauris condimentum eget. Vivamus non turpis elit. Aenean
                                            ultricies nisl sit amet.</p>
                                        <div class="team__social">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                            <a href="#"><i class="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Team Section End --> */}

                {/* <!-- Footer Section Begin --> */}
                <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
                    <div class="container">
                        <div class="footer__top">
                            <div class="row">
                                <div class="col-lg-8 col-md-6">
                                    <div class="footer__top__text">
                                        <h2>Ready To Work With Us?</h2>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="footer__top__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Enter your email..." />
                                            <button type="submit"><i class="fa fa-send"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" /></a>
                                    </div>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                    <div class="footer__social">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Company</h6>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Our Works</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="#">FAQs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#">Architecture</a></li>
                                        <li><a href="#">Interior Design</a></li>
                                        <li><a href="#">Exterior Design</a></li>
                                        <li><a href="#">Planning</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer__address">
                                    <h6>Get In Touch</h6>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="copyright">
                            <div class="row">
                                <div class="col-lg-8 col-md-7">
                                    <div class="copyright__text">
                                        <p>Copyright © <script>
                                            document.write(new Date().getFullYear());
                                        </script> All rights reserved | This template is made with <i class="fa fa-heart-o"
                                            aria-hidden="true"></i> by <a href="https://colorlib.com"
                                                target="_blank" rel="noreferrer">Colorlib</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="copyright__widget">
                                        <a href="#">Terms of use</a>
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- Footer Section End --> */}


            </body>

        </>
    )
}