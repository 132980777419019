import React, { useEffect, useState } from 'react';
import Home from "./components/home";
import { Route, Routes } from "react-router-dom"
import About from './components/About';
import Admin from './components/Admin';
import Contact from './components/contact';
import Services from './components/service-page';
import Events from './components/events';
import Blog from './components/blog';
import PhD from './components/Resources/phd';
import Masters from './components/Resources/masters';
import Bachelors from './components/Resources/bachelors';
import Login from './components/login';
import { store } from './actions/reducer'
import { Provider } from 'react-redux'
import EventDetail from './components/event-detail';
import BlogDetail from './components/blog-details';
import PageNotFound from './components/404Page';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(setLoading(false), 1200);
  }, []);

  return (
    <Provider store={store}>
      <div className="App" >
        {/* <!-- Page Preloder --> */}
        {loading === true ? (
          <div id="preloder">
            <div class="loader"></div>
          </div>
        ) : null}
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/admin' element={<Admin/>}></Route>
          <Route exact path='/about' element={< About />}></Route>
          <Route exact path='/contact' element={<Contact />}></Route>
          <Route exact path='/bachelors' element={<Bachelors />}></Route>
          <Route exact path='/masters' element={<Masters />}></Route>
          <Route exact path='/phd' element={<PhD />}></Route>
          <Route exact path='/services' element={<Services />}></Route>
          <Route exact path='/events' element={<Events />}></Route>
          <Route exact path='/blog' element={<Blog />}></Route>
          <Route exact path='login' element={<Login />}></Route>
          <Route exact path='/events/:id' element={<EventDetail />}></Route>
          <Route exact path='/blog/:id' element={<BlogDetail />}></Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Provider >
  );
}

export default App;
