/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import $ from 'jquery';

export default function Admin() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });
    return (
        <>
            <body>
                {/* <!-- Offcanvas Menu Begin --> */}
                <div class="offcanvas-menu-overlay"></div>
                <div class="offcanvas-menu-wrapper">
                    <div class="offcanvas__logo">
                        <a href="#"><img src="img/logo.png" alt="" /></a>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                    <div class="offcanvas__widget">
                        <span>Call us for any questions</span>
                        <h4>+01 123 456 789</h4>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header class="header header-normal set-bg" data-setbg="img/little-header.jpg">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="header__logo">
                                    <a href="/" class="header__logo-title"><img class="logo" src="img/logo/logo-1.png" width="40" alt="" /> The STEMM <span>Factor</span></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <nav class="header__menu mobile-menu">
                                    <ul>
                                        <li><a href="/">Admin's Dash board</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-3">
                                <div class="header__widget">
                                    <span>Call us for any questions</span>
                                    <h4>+01 123 456 789</h4>
                                </div>
                            </div>
                        </div>
                        <div class="canvas__open"><i class="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}
          {/* Admin Scetion section begin*/}
          <div>
            <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Dropdown button
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Bachelors</a>
    <a class="dropdown-item" href="#">Masters</a>
    <a class="dropdown-item" href="#">PhD</a>
  </div>
            </div>
          <form>
  <div class="form-group">
    <label for="exampleFormControlInput1">Title</label>
    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="title"/>
  </div>

  <div class="form-group">
    <label for="exampleFormControlTextarea1">Description</label>
    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <div class="form-group">
    <label for="exampleFormControlInput1">Author</label>
    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Author"/>
              </div>
              <button type="button" class="btn btn-warning">Post</button>
</form>  
          </div>
          {/*admin section end*/}
                {/* <!-- Footer Section Begin --> */}
                <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
                    <div class="container">
                        <div class="footer__top">
                            <div class="row">
                                <div class="col-lg-8 col-md-6">
                                    <div class="footer__top__text">
                                        <h2>Ready To Work With Us?</h2>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="footer__top__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Enter your email..." />
                                            <button type="submit"><i class="fa fa-send"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" /></a>
                                    </div>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                    <div class="footer__social">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Company</h6>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Our Works</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="#">FAQs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#">Architecture</a></li>
                                        <li><a href="#">Interior Design</a></li>
                                        <li><a href="#">Exterior Design</a></li>
                                        <li><a href="#">Planning</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer__address">
                                    <h6>Get In Touch</h6>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="copyright">
                            <div class="row">
                                <div class="col-lg-8 col-md-7">
                                    <div class="copyright__text">
                                        <p>Copyright © <script>
                                            document.write(new Date().getFullYear());
                                        </script> All rights reserved | This template is made with <i class="fa fa-heart-o"
                                            aria-hidden="true"></i> by <a href="https://colorlib.com"
                                                target="_blank" rel="noreferrer">Colorlib</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="copyright__widget">
                                        <a href="#">Terms of use</a>
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </body>

        </>
    )
}