/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import $ from 'jquery';
import { useGetEventsQuery } from '../../services/events';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader';

export default function Events() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });
    const { data, error, isLoading } = useGetEventsQuery()
    const navigate = useNavigate()
    const events = data || []


    return (
        <>
            <body>

                {/* <!-- Offcanvas Menu Begin --> */}
                <div class="offcanvas-menu-overlay"></div>
                <div class="offcanvas-menu-wrapper">
                    <div class="offcanvas__logo">
                        <a href="#"><img src="img/logo.png" alt="" /></a>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                    <div class="offcanvas__widget">
                        <span>Call us for any questions</span>
                        <h4>+01 123 456 789</h4>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header class="header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="header__logo">
                                    <a href="/" class="header__logo-title"><img class="logo" src="img/logo/logo-1.png" width="40" alt="" /> The STEMM <span>Factor</span></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <nav class="header__menu mobile-menu">
                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li className='active'><a href="/events">Events</a></li>
                                        <li><a href="/about">About</a></li>
                                        <li><a href="#">Resources</a>
                                            <ul class="dropdown">
                                                <li><a href="/bachelors">Bachelors</a></li>
                                                <li><a href="/masters">Masters</a></li>
                                                <li><a href="/phd">PhD</a></li>
                                                <li><a href="/services">Services</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/blog">Blog</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-lg-3">
                                <div class="header__widget">
                                    <span>Call us for any questions</span>
                                    <h4>+01 123 456 789</h4>
                                </div>
                            </div>
                        </div>
                        <div class="canvas__open"><i class="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}

                {/* <!-- Breadcrumb Section Begin --> */}
                <div class="breadcrumb-option spad set-bg" data-setbg="https://source.unsplash.com/random/1600x900">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <div class="breadcrumb__text">
                                    <h2>Our Events</h2>
                                    <div class="breadcrumb__links">
                                        <a href="/">Home</a>
                                        <span>Events</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Section End --> */}
                {isLoading ? <Loader /> : (
                    <>
                        {/* <!-- Project Page Section Begin --> */}
                        < section class="project-page spad">
                            <div class="container">
                                {events.length ? (
                                    <div class="row">
                                        {events.map(event => (
                                            <div class="col-lg-6 col-md-6 col-sm-6 shadow-sm p-3  bg-white rounded">
                                                <div class="project__item" onClick={() => navigate(`/events/${ event._id }`)}>
                                                    {/* <img src="https://www.iconpacks.net/icons/2/free-event-icon-2319-thumb.png" alt={event.heading} /> */}
                                                    <h4 >{event.heading}</h4>
                                                    <p>{event.description}</p>
                                                    <strong>{event.location}</strong>
                                                </div>
                                            </div>
                                        ))}
                                        {/* <div class="col-lg-12 text-center">
                                        <a href="#" class="primary-btn normal-btn">Load More</a>
                                    </div> */}
                                    </div>
                                ) : (
                                    <div class="row text-center">No Data Found</div>
                                )}
                            </div>
                        </section>
                        {/* <!-- Project Page Section End --> */}
                    </>
                )}
                {/* <!-- Footer Section Begin --> */}
                <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
                    <div class="container">
                        <div class="footer__top">
                            <div class="row">
                                <div class="col-lg-8 col-md-6">
                                    <div class="footer__top__text">
                                        <h2>Ready To Work With Us?</h2>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="footer__top__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Enter your email..." />
                                            <button type="submit"><i class="fa fa-send"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" /></a>
                                    </div>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                    <div class="footer__social">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                        <a href="#"><i class="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Company</h6>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Our Works</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="#">FAQs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-6">
                                <div class="footer__widget">
                                    <h6>Services</h6>
                                    <ul>
                                        <li><a href="#">Architecture</a></li>
                                        <li><a href="#">Interior Design</a></li>
                                        <li><a href="#">Exterior Design</a></li>
                                        <li><a href="#">Planning</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="footer__address">
                                    <h6>Get In Touch</h6>
                                    <p>7176 Blue Spring Lane <br />Santa Monica, CA 90403</p>
                                    <ul>
                                        <li>Info.colorlib@gmail.com</li>
                                        <li>+84 123 456 789</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="copyright">
                            <div class="row">
                                <div class="col-lg-8 col-md-7">
                                    <div class="copyright__text">
                                        <p>Copyright © <script>
                                            document.write(new Date().getFullYear());
                                        </script> All rights reserved | This template is made with <i class="fa fa-heart-o"
                                            aria-hidden="true"></i> by <a href="https://colorlib.com"
                                                target="_blank">Colorlib</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-5">
                                    <div class="copyright__widget">
                                        <a href="#">Terms of use</a>
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- Footer Section End --> */}


            </body>


        </>
    )
}