/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import { useParams } from 'react-router-dom';
import { useGetBlogsByIdQuery } from '../../services/blogs';
import Loader from '../loader';

export default function BlogDetail() {
    $('.set-bg').each(function () {
        var bg = $(this).data('setbg');
        $(this).css('background-image', 'url(' + bg + ')');
    });

    const { id } = useParams()
    const { data, error, isLoading } = useGetBlogsByIdQuery({ id });
    return (
        <>
            {isLoading ? (<Loader />) : (
                <>
                    {!error ? (
                        <>
                            Blog detail {data.id}
                        </>
                    ) : null}
                </>
            )}

        </>
    )
}